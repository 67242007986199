@import '../styles/utils';
@import '../styles/colors';
@import '../styles/btn';

.row {
  &-toolbar {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-footer {
    margin-top: 1rem;
  }
}

.thumbnail {
  &.focus {
    background-color: yellow;
  }
}

body.red {
  background-color: red;
}

//
// VOD
// =============================================================================
.cts-video-thumbnails {
  & .row {
    margin-bottom: 1rem;
  }
}

.cts-video-thumbnail {
  display: block;
  width: 100%;
  border: none;
  border-radius: 1rem;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  background-color: inherit;

  & img {
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__caption {
    text-align: left;
    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    .label {
      font-weight: 400;
    }
  }
}

//
// 河川水位情報
// =============================================================================
.cts-river-info-thumbnail {
  display: block;
  width: 100%;
  border: none;
  border-radius: 1rem;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  background-color: inherit;

  & img {
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__caption {
    text-align: left;
    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    h3 {
      margin-top: 0;
    }

    .label {
      font-weight: 400;
    }
  }
}

.cts-river-info-camera-point {
  position: absolute;
  display: inline-block;
  width: 1.5%;
  height: 1.5% * 16 / 9;
  background-color: blue;
  border: 0;
  padding: 0;

  &--selected {
    background-color: red;
  }
}

//
// 動画再生
// =============================================================================
.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
