//
// White Space
// --------------------------------------------------
.white-space {
  white-space: nowrap;

  &:after {
    content: '\00a0';
  }
}
